#contact-container {
    width: 50%;
    margin: 0 auto;
    color: #303030 !important;
}

#contact-inner-container {
    width: 100%;
}

@media screen and (max-width: 600px) {
    #contact-container {
        width: 90%;
    }
} 