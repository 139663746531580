.vk-navbar {
    font-size: 20px;
    color: #303030 !important;
    margin: 0 auto 15px auto;
    word-spacing:10px;

}
.navbar {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 5px;
}
.nav-item {
    margin: 0 30px;
}

.active {
    color: #303030;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #1b1b1b;
}