#showreel-subtitle {
    font-family: 'Playfair Display', serif;
    font-weight: 100;
    letter-spacing: 10px;
    text-align: center;
    color: #303030 !important;
}

.reel-container{
    align-items: center;
    width: 100%;
}