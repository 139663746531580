.service-container {
    display: flex;
    flex-direction: row;
    width: 600px;
    margin: 30px auto 0 auto;
    align-content: baseline;
    height: 100%;
    font-family: 'Playfair Display', serif;
    font-weight: 100;
    font-size: 18px;
    color: #303030 !important;
}
.service-imge img {
    height: 360px;
    width: auto;
    transition: transform 1s;
    border-radius: 3px;
}

.service-imge {
    flex: 1 1;
    text-align: right;
    margin-right: 30px;
    height: 360px;
    display: inline-block;
    overflow: hidden;
    border-radius: 3px;
}

.service-info {
    flex: 1 1;
    text-align: center;
    margin-left: 30px;
    font-size: 20px;
}

.service-list {
    margin: auto 0;
    flex: 1 1;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.service-imge img:hover {
    transform:scale(1.1);
    -ms-transform:scale(1.1); /* IE 9 */
    -moz-transform:scale(1.1); /* Firefox */
    -webkit-transform:scale(1.1); /* Safari and Chrome */
    -o-transform:scale(1.1S); /* Opera */
}

.service-footer {
 font-size: 14px;
 color: #757575;
 margin: 30px;
 text-align: center;
}

@media (max-width: 600px) {
    .service-container {
        flex-direction: column;
        margin: 0;
        width: 100%;
    }
    .service-imge {
        text-align: center;
        margin: 30px 15px;
    }

    .service-info {
        text-align: center;
        margin: auto 15px;
    }
    h3 {
        padding: 30px;
    }
}

@media (max-width: 375px) {
    .service-imge img {
        height: 320px;
        width: auto;
    }
}
