.photos-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
    align-content: baseline;
    height: 100%;
    font-family: 'Playfair Display', serif;
    font-weight: 100;
    font-size: 18px;
    color: #303030 !important;
}
.photos-container img {
    /* height: 360px; */
    width: auto;
    transition: transform 0.5s;
}

.photos-container img:hover {
    transform:scale(1.07);
    -ms-transform:scale(1.07); /* IE 9 */
    -moz-transform:scale(1.07); /* Firefox */
    -webkit-transform:scale(1.07); /* Safari and Chrome */
    -o-transform:scale(1.07S); /* Opera */
}

#photos-subtitle {
    font-family: 'Playfair Display', serif;
    font-weight: 100;
    letter-spacing: 10px;
    text-align: center;
    color: #303030 !important;
    margin-bottom: 30px;
}

.react-photo-gallery--gallery > div {
    justify-content: center;
}

@media (min-width: 600px) {
    .photos-container {
        width: 80%;
    }
}
