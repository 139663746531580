/* .responsive-container {
    position: relative;
    padding-bottom: 53.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
.responsive-container,
    .responsive-container iframe {
    max-width: 1800px;
    max-height: 759px;
    margin: 0 auto;
}
.responsive-container iframe {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
}

 */

.responsive-container {
    width: 80%;
    height: auto;
    margin: 0 auto;
}

.custom-iframe {
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    width: 1280px; 
    height: 540px;
    margin: 0 auto;
}

.video-container {
    position: relative;
    /* padding-bottom: 56.25% 16:9; */
    padding-top: 10px;
    /* height: 0; */
}

@media screen and (max-width: 800px) {
    .responsive-container {
        width: 100%;
        height: 100%;
    }
} 