@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body {
  background-position: fixed;
  background-blend-mode: overlay;
  background-image: url(/static/media/bg.eb3142ac.webp);
  background-color: rgb(243 243 243);
  background-size: cover;
  background-attachment: fixed;
}

.header {
  height: auto;
}


.main {
  flex: auto;
}

.footer {
  height: 90px;
  bottom: 0;
  width: 100%;
  color: #fff;
  margin-top: 50px;
}

.vk-flex-container {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
}

.bg-blk {
  background-color: #1b1b1b;
}

.txt-center {
  text-align: center;
}
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}
#header-title {
    font-weight: 200;
    font-family: 'Cormorant Garamond', serif;
    font-size: 40px;
    letter-spacing: 15px;
}
#subtitle {
    font-family: 'Playfair Display', serif;
    font-weight: 100;
    font-size: 18px;
    letter-spacing: 10px;
}

.header-container {
    display: flex;
    height: 250px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #303030 !important;
}

.header-content {
    align-items: center;
}

#hero-logo {
    width: 100%;
    height: 100px;
    background-color: #1b1b1b;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
}

#hero-logo > img {
    height: 80px;
    align-items: center;
    transition: transform 1s;
}

#hero-logo > img:hover {
        transform: scale(1.1);
}

@media screen and (max-width: 800px) {
    #subtitle {
        font-size: 15px;
        letter-spacing: 5px;
    }
    
} 
.vk-navbar {
    font-size: 20px;
    color: #303030 !important;
    margin: 0 auto 15px auto;
    word-spacing:10px;

}
.navbar {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 5px;
}
.nav-item {
    margin: 0 30px;
}

.active {
    color: #303030;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #1b1b1b;
}
/* .responsive-container {
    position: relative;
    padding-bottom: 53.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
.responsive-container,
    .responsive-container iframe {
    max-width: 1800px;
    max-height: 759px;
    margin: 0 auto;
}
.responsive-container iframe {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
}

 */

.responsive-container {
    width: 80%;
    height: auto;
    margin: 0 auto;
}

.custom-iframe {
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    width: 1280px; 
    height: 540px;
    margin: 0 auto;
}

.video-container {
    position: relative;
    /* padding-bottom: 56.25% 16:9; */
    padding-top: 10px;
    /* height: 0; */
}

@media screen and (max-width: 800px) {
    .responsive-container {
        width: 100%;
        height: 100%;
    }
} 
#showreel-subtitle {
    font-family: 'Playfair Display', serif;
    font-weight: 100;
    letter-spacing: 10px;
    text-align: center;
    color: #303030 !important;
}

.reel-container{
    align-items: center;
    width: 100%;
}
.videos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.videos-item  {
    height: 260px;
    margin-bottom: 3%;
    display: inline-block;
    overflow: hidden;
}

.videos-item > img {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
    height: 300px;
    width: auto;
    transition: transform 1s;
    border-radius: 3px;
} 

.videos-item > img:hover {
    transform:scale(1.1);
    -ms-transform:scale(1.1); /* IE 9 */
    -moz-transform:scale(1.1); /* Firefox */
    -webkit-transform:scale(1.1); /* Safari and Chrome */
    -o-transform:scale(1.1S); /* Opera */
}


@media (max-width: 600px) {
    .videos-container {
        flex-direction: column;
        margin: 0;
        width: 100%;
    }
}

@media (max-width: 375px) {
    .videos-item > img {
        width: 320px;
        height: auto;

    }
    .videos-item  {
        margin-bottom: 0;
        padding: 10px;
    }
}

@media (min-width: 1580px) {
    .videos-item  {
        height: 300px;
    }
}
.player-container{
    align-items: center;
    width: 100%;
}

.back-to-videos{
    text-align: center;
    width: 100%;
}

.back-to-videos > div{
    margin-top: 30px;
    font-weight: 500;
    color: #1b1b1b;
}


@media (max-width: 800px) {
    .back-to-videos > div{
        margin-top: 0;
    }
}
.photos-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
    align-content: baseline;
    height: 100%;
    font-family: 'Playfair Display', serif;
    font-weight: 100;
    font-size: 18px;
    color: #303030 !important;
}
.photos-container img {
    /* height: 360px; */
    width: auto;
    transition: transform 0.5s;
}

.photos-container img:hover {
    transform:scale(1.07);
    -ms-transform:scale(1.07); /* IE 9 */
    -moz-transform:scale(1.07); /* Firefox */
    -webkit-transform:scale(1.07); /* Safari and Chrome */
    -o-transform:scale(1.07S); /* Opera */
}

#photos-subtitle {
    font-family: 'Playfair Display', serif;
    font-weight: 100;
    letter-spacing: 10px;
    text-align: center;
    color: #303030 !important;
    margin-bottom: 30px;
}

.react-photo-gallery--gallery > div {
    justify-content: center;
}

@media (min-width: 600px) {
    .photos-container {
        width: 80%;
    }
}

.service-container {
    display: flex;
    flex-direction: row;
    width: 600px;
    margin: 30px auto 0 auto;
    align-content: baseline;
    height: 100%;
    font-family: 'Playfair Display', serif;
    font-weight: 100;
    font-size: 18px;
    color: #303030 !important;
}
.service-imge img {
    height: 360px;
    width: auto;
    transition: transform 1s;
    border-radius: 3px;
}

.service-imge {
    flex: 1 1;
    text-align: right;
    margin-right: 30px;
    height: 360px;
    display: inline-block;
    overflow: hidden;
    border-radius: 3px;
}

.service-info {
    flex: 1 1;
    text-align: center;
    margin-left: 30px;
    font-size: 20px;
}

.service-list {
    margin: auto 0;
    flex: 1 1;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.service-imge img:hover {
    transform:scale(1.1);
    -ms-transform:scale(1.1); /* IE 9 */
    -moz-transform:scale(1.1); /* Firefox */
    -webkit-transform:scale(1.1); /* Safari and Chrome */
    -o-transform:scale(1.1S); /* Opera */
}

.service-footer {
 font-size: 14px;
 color: #757575;
 margin: 30px;
 text-align: center;
}

@media (max-width: 600px) {
    .service-container {
        flex-direction: column;
        margin: 0;
        width: 100%;
    }
    .service-imge {
        text-align: center;
        margin: 30px 15px;
    }

    .service-info {
        text-align: center;
        margin: auto 15px;
    }
    h3 {
        padding: 30px;
    }
}

@media (max-width: 375px) {
    .service-imge img {
        height: 320px;
        width: auto;
    }
}

#contact-container {
    width: 50%;
    margin: 0 auto;
    color: #303030 !important;
}

#contact-inner-container {
    width: 100%;
}

@media screen and (max-width: 600px) {
    #contact-container {
        width: 90%;
    }
} 
#vk-footer {
    width: 100%;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
    bottom: 0;
    position: relative;
}

#vk-footer > a {
    color: #fff;
    background: none;
    -webkit-text-fill-color: #fff;
}

#ig-link:hover {
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#yt-link:hover {
    background: linear-gradient(180deg, #ffffff, 50%, #dc3545);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#in-link:hover {
    background: #0A66C2;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#fb-link:hover {
    background: #3b5998;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.copy-container {
    font-size: 12px;
}
.notfound-container img {
   max-width: 500px;
}

.notfound-container a {
    text-decoration: underline;
    color: rgb(28, 26, 37);
}

@media (max-width: 480px) {
    .notfound-container img {
        max-width: 280px;
     }
}
