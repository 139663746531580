.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body {
  background-position: fixed;
  background-blend-mode: overlay;
  background-image: url("./assets/images/bg.webp");
  background-color: rgb(243 243 243);
  background-size: cover;
  background-attachment: fixed;
}

.header {
  height: auto;
}


.main {
  flex: auto;
}

.footer {
  height: 90px;
  bottom: 0;
  width: 100%;
  color: #fff;
  margin-top: 50px;
}

.vk-flex-container {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
}

.bg-blk {
  background-color: #1b1b1b;
}

.txt-center {
  text-align: center;
}
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}