#header-title {
    font-weight: 200;
    font-family: 'Cormorant Garamond', serif;
    font-size: 40px;
    letter-spacing: 15px;
}
#subtitle {
    font-family: 'Playfair Display', serif;
    font-weight: 100;
    font-size: 18px;
    letter-spacing: 10px;
}

.header-container {
    display: flex;
    height: 250px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #303030 !important;
}

.header-content {
    align-items: center;
}

#hero-logo {
    width: 100%;
    height: 100px;
    background-color: #1b1b1b;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
}

#hero-logo > img {
    height: 80px;
    align-items: center;
    transition: transform 1s;
}

#hero-logo > img:hover {
        transform: scale(1.1);
}

@media screen and (max-width: 800px) {
    #subtitle {
        font-size: 15px;
        letter-spacing: 5px;
    }
    
} 