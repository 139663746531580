.videos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.videos-item  {
    height: 260px;
    margin-bottom: 3%;
    display: inline-block;
    overflow: hidden;
}

.videos-item > img {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
    height: 300px;
    width: auto;
    transition: transform 1s;
    border-radius: 3px;
} 

.videos-item > img:hover {
    transform:scale(1.1);
    -ms-transform:scale(1.1); /* IE 9 */
    -moz-transform:scale(1.1); /* Firefox */
    -webkit-transform:scale(1.1); /* Safari and Chrome */
    -o-transform:scale(1.1S); /* Opera */
}


@media (max-width: 600px) {
    .videos-container {
        flex-direction: column;
        margin: 0;
        width: 100%;
    }
}

@media (max-width: 375px) {
    .videos-item > img {
        width: 320px;
        height: auto;

    }
    .videos-item  {
        margin-bottom: 0;
        padding: 10px;
    }
}

@media (min-width: 1580px) {
    .videos-item  {
        height: 300px;
    }
}