.player-container{
    align-items: center;
    width: 100%;
}

.back-to-videos{
    text-align: center;
    width: 100%;
}

.back-to-videos > div{
    margin-top: 30px;
    font-weight: 500;
    color: #1b1b1b;
}


@media (max-width: 800px) {
    .back-to-videos > div{
        margin-top: 0;
    }
}